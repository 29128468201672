import React, { useState, useEffect, Fragment } from 'react';
import { RuxIcon, RuxTrack, RuxCheckbox, RuxDialog, RuxButton, RuxSlider } from "@astrouxds/react";
import { getBookings } from "../../../apis/bookingApi";

const TimelineComponent = ({ tracks, subtracks, regions, zoom, setZoom, handleTimelineFormSubmit, upcomingPredictions, showConfirmed, setShowConfirmed, showRequested, setShowRequested, showRejected, setShowRejected, showPredictions, setShowPredictions }) => {
    const [now, setNow] = useState(new Date());
    const [expanded, setExpanded] = useState({});
    const [allBookings, setAllBookings] = useState();
    const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            let shouldUpdate = false;

            regions.forEach(region => {
                const time_aos = new Date(region.time_aos);
                const time_los = new Date(region.time_los);

                if (time_aos.getTime() <= now.getTime() && time_los.getTime() >= now.getTime()) {
                    shouldUpdate = true;
                }
            });

            if (shouldUpdate) {
                setNow(new Date());
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [regions]);

    useEffect(() => {
        const loadAllBookings = async () => {
            try {
                const bookings = await getBookings();
                setAllBookings(bookings || []);
            } catch (error) {
                console.error('Error loading bookings:', error);
            }
        };
        loadAllBookings()
        const interval = setInterval(loadAllBookings, 10000);

        return () => clearInterval(interval);
    }, []);

    const toggleExpand = (id, parentId) => {
        setExpanded(prev => {
            const newExpanded = { ...prev };
            const key = parentId ? `${parentId}-${id}` : id;
            newExpanded[key] = !newExpanded[key];
            return newExpanded;
        });
    };

    const togglePredictionsVisibility = () => {
        setShowPredictions(prevState => {
            if(prevState == false) {
                handleTimelineFormSubmit()
            } 
            return !prevState
        });
    };

    const toggleShowConfirmed = () => {
        setShowConfirmed(prevState => !prevState);
    };

    const toggleShowRequested = () => {
        setShowRequested(prevState => !prevState);
    };

    const toggleShowRejected = () => {
        setShowRejected(prevState => !prevState);
    };

    const renderRegion = (regions) => {
        return regions?.map(region => (
            <rux-time-region key={region.id} start={region.start} end={region.end}
                status={getBookingIconStatus(region)}>
                {region.label}
            </rux-time-region>
        ));
    };

    const getBookingIconStatus = (booking) => {
        // ["normal","caution","serious", "critical"]
        const status_map = {
            OK: 'normal',
            WARNING: 'caution',
            STANDBY: 'standby',
            ERR: 'critical',
            null: ''
        };

        const now = new Date();
        const time_aos = new Date(booking.time_aos);
        const time_los = new Date(booking.time_los);

        if (time_aos.getTime() <= now.getTime() && time_los.getTime() >= now.getTime()) {
            return 'caution';
        } else {
            return status_map[booking.status?.level || null] || 'off';
        }
    }

    const renderTrack = (track, parentId) => {
        const isExpanded = expanded[parentId ? `${parentId}-${track.id}` : track.id];
        const hasSubTracks = track.subTrack && track.subTrack.length > 0;

        return (
            <Fragment>
                <rux-track key={track.id} class={parentId ? 'sub-track' : ''}>
                    <div
                        slot="label"
                        style={{ display: "flex", alignItems: "center", cursor: !parentId ? "pointer" : null }}
                        onClick={() => toggleExpand(track.id, parentId)}
                    >
                        {!parentId && (
                            <RuxIcon
                                size="small"
                                icon={isExpanded ? "arrow-drop-down" : "arrow-right"}
                                style={{ opacity: hasSubTracks ? 1 : 0 }}
                            />
                        )}
                        {track.label}
                    </div>
                    {track.regions && renderRegion(track.regions)}
                    {!isExpanded && track.subTrack?.flatMap(subTrack => subTrack.regions).map(region => renderRegion([region]))}
                </rux-track>
                {isExpanded && track.subTrack?.map(subTrack => renderTrack(subTrack, track.id))}
            </Fragment>
        );
    };

    const calculateViewWindow = () => {
        const hour = 60 * 60 * 1000;

        let viewStart = new Date();
        viewStart.setTime(now.getTime());
        viewStart.setMinutes(0, 0, 0);

        let viewEnd = new Date();
        viewEnd.setTime(viewStart.getTime() + 2 * 24 * hour);

        return { viewStart, viewEnd };
    };

    const { viewStart, viewEnd } = calculateViewWindow();

    const transformDataToTimelineFormat = (tracks, subtracks, regions) => {
        return tracks.map(track => {
            const trackData = {
                id: track.object_id || track.station_id,
                label: track.name || track.station_name,
                subTrack: subtracks
                    .filter(subtrack => {
                        const hasBooking = allBookings && allBookings.some(booking => {
                            const bookingStart = new Date(booking.time_aos);
                            const bookingEnd = new Date(booking.time_los);

                            return (
                                (booking.object?.object_id === subtrack.object_id && track.station_id === booking.station?.station_id) ||
                                (booking.station?.station_id === subtrack.station_id && track.object_id === booking.object?.object_id)
                            ) && (
                                    (bookingStart >= viewStart && bookingStart <= viewEnd) ||
                                    (bookingEnd >= viewStart && bookingEnd <= viewEnd)
                                );
                        });

                        const hasPrediction = showPredictions && upcomingPredictions && Array.isArray(upcomingPredictions) && upcomingPredictions.some(prediction => {

                            const predictionStart = new Date(prediction.time_aos);
                            const predictionEnd = new Date(prediction.time_los);
                            return (
                                (prediction.object.object_id === subtrack.object_id && track.station_id === prediction.station.station_id) ||
                                (prediction.station.station_id === subtrack.station_id && track.object_id === prediction.object.object_id)
                            ) && (
                                    (predictionStart >= viewStart && predictionStart <= viewEnd) ||
                                    (predictionEnd >= viewStart && predictionEnd <= viewEnd)
                                );
                        });

                        return hasBooking || hasPrediction;
                    })
                    .map(subtrack => {
                        let filteredRegions = regions.filter(region => {
                            return (
                                (region.object?.object_id === subtrack.object_id && track.station_id === region.station?.station_id) ||
                                (region.station?.station_id === subtrack.station_id && track.object_id === region.object?.object_id)
                            ) && (
                                    (region.status?.status_id === 'CONFIRMED' && showConfirmed) ||
                                    (region.status?.status_id === 'REQUESTED' && showRequested) ||
                                    (region.status?.status_id === 'REJECTED' && showRejected)
                                );
                        });

                        let filteredPredictions = showPredictions ? (upcomingPredictions || []).filter(prediction => {
                            if (prediction.booking_id == null) {
                                return (
                                    (prediction.object.object_id === subtrack.object_id && track.station_id === prediction.station.station_id) ||
                                    (prediction.station.station_id === subtrack.station_id && track.object_id === prediction.object.object_id)
                                );
                            }
                        }) : [];

                        const subTrackData = {
                            id: subtrack.object_id || subtrack.station_id,
                            label: subtrack.name || subtrack.station_name,
                            regions: [
                                ...filteredRegions.map(region => ({
                                    id: region.booking_id,
                                    start: region.time_aos,
                                    end: region.time_los,
                                    label: region.object?.name || region.station?.station_name,
                                    status: region.status || ''
                                })),
                                ...filteredPredictions.map(prediction => ({
                                    id: prediction.prediction_id,
                                    start: prediction.time_aos,
                                    end: prediction.time_los,
                                    label: prediction.object.name || prediction.station.station_name,
                                    status: 'off'
                                }))
                            ]
                        };

                        return (subTrackData.regions && subTrackData.regions.length > 0) ? subTrackData : null;
                    })
                    .filter(Boolean)
            };
            return trackData;
        });
    };

    const timelineData = transformDataToTimelineFormat(tracks, subtracks, regions);

    const openFilterDialog = async () => {
        setIsFilterDialogOpen(true);
    };

    const closeFilterDialog = async () => {
        setIsFilterDialogOpen(false);
    };

    const handleSliderChange = (event) => {
        const newZoom = parseInt(event.target.value);
        setZoom(newZoom);
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={openFilterDialog}>
                    <RuxIcon size="small" icon="chevron-right"></RuxIcon>
                    <a style={{ textDecoration: 'none', marginLeft: '5px' }}>Filter</a>
                </div>
                <div style={{ marginLeft: '20px', width: '300px', display: 'flex', flexDirection: 'column' }}>
            <div style={{display: 'flex', justifyContent: 'center'}}>Zoom</div>
            <RuxSlider max="20" min="1" value={zoom} onRuxinput={handleSliderChange}>Zoom</RuxSlider>
        </div>
            </div>
            <RuxDialog open={isFilterDialogOpen} close={closeFilterDialog}>
                <span slot="header">Filter</span>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 'bold', marginBottom: '10px' }}>Predictions</span>
                    <RuxCheckbox
                        name='Display Predictions'
                        label='Display Predictions'
                        checked={showPredictions}
                        onRuxchange={togglePredictionsVisibility}
                    ></RuxCheckbox>
                </div>

                <div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontWeight: 'bold', marginBottom: '10px', marginTop: '20px' }}>Bookings</span>
                        <RuxCheckbox
                            name='DisplayConfirmed'
                            label='Display Confirmed Bookings'
                            checked={showConfirmed}
                            onRuxchange={toggleShowConfirmed}
                        ></RuxCheckbox>

                        <RuxCheckbox
                            name='DisplayRequested'
                            label='Display Requested Bookings'
                            checked={showRequested}
                            onRuxchange={toggleShowRequested}
                        ></RuxCheckbox>

                        <RuxCheckbox
                            name='DisplayRejected'
                            label='Display Rejected Bookings'
                            checked={showRejected}
                            onRuxchange={toggleShowRejected}
                        ></RuxCheckbox>
                    </div>
                </div>

                <div slot="footer" onClick={closeFilterDialog} >
                    <RuxButton style={{ float: 'right' }} id="confirm" onClick={closeFilterDialog} >
                        Confirm
                    </RuxButton>
                </div>
            </RuxDialog>

            <rux-timeline
                style={{ marginTop: '20px' }}
                timezone="UTC"
                start={viewStart.toISOString()}
                end={viewEnd.toISOString()}
                playhead={now.toISOString()}
                interval="hour"
                zoom={zoom}
                has-played-indicator=""
            >
                {timelineData?.map(track => renderTrack(track))}
                <RuxTrack slot="ruler">
                    <rux-ruler></rux-ruler>
                </RuxTrack>
            </rux-timeline>
        </div>
    );
};

export default TimelineComponent;
